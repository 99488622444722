var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "out", staticClass: "position-r match-parent" }, [
    _c(
      "div",
      { staticClass: "match-parent", on: { mouseenter: _vm.showPop } },
      [_vm._t("triggerContent")],
      2
    ),
    _vm._v(" "),
    _vm.popVisible
      ? _c(
          "div",
          {
            ref: "pop",
            staticClass: "popContainer",
            style: _vm.popSize,
            on: { mouseleave: _vm.hidePop },
          },
          [_vm._t("popContent")],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }