<template>
    <div ref="out" class="position-r match-parent">
        <div class="match-parent" @mouseenter="showPop">
            <slot name="triggerContent"/>
        </div>
        <div v-if="popVisible" ref="pop" class="popContainer" :style="popSize" style=""
             @mouseleave="hidePop">
            <slot name="popContent"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: "bottomPopView",
        data() {
            return {
                popVisible: false,
                popLeft: 0,
            }
        },
        props: {
            popWidth: {
                type: String,
                default() {
                    return "100%";
                }
            },
        },
        computed: {
            popSize() {
                return `width: ${this.popWidth};left: ${this.popLeft}px`;
            }
        },
        methods: {
            showPop() {
                this.popVisible = true;
                this.$nextTick(this.calculateLeft);
            },
            hidePop() {
                this.popVisible = false;
            },
            calculateLeft() {
                // 获取到容器宽度
                if (!this.$refs.pop) {
                    return 0;
                }
                let containerWidth = this.$refs.out.offsetWidth;
                // 获取到pop宽度
                let popWidth = this.$refs.pop.offsetWidth;
                this.popLeft = (containerWidth - popWidth) / 2;
            },
        }
    }
</script>

<style scoped>

    .popContainer {
        height: fit-content;
        position: absolute;
        top: 0;
        z-index: 2;
    }
</style>